
//        $('.chooser-modal').on('shown.bs.modal', function () {     
//    ModalChooser.init($(this));
//});

(function ($) {
    $.fn.onEnter = function (func) {
        this.bind('keypress', function (e) {

            if (e.keyCode == 13) {
                e.preventDefault();
                func.apply(this, [e]);
            }
        });
        return this;
    };
})(jQuery);

var ModalTextInput = function () {
    return {
        input: null,
        getInput: function () {
            return this.input
        },
        setInput: function (input) {
            this.input = $(input)
        }
    }
}()

$('.btn-trigger-modal').on('click', function () {
    ModalTextInput.setInput($(this).closest('.input-group').find('input[type=text]'))


});

var ModalChooser = function () {
    return {
        modal: null,
        //   textinput: null,
        searchinput: null,
        imageholder: null,
        loadedimages: null,
        selectedimage: null,
        pagination: 1,
        num_loaded: 0,
        num_total: 0,
        jstree: null,
        current_dir: null,
        current_search_function: null,
        init: function (modal) {


            this.setModal(modal);

            this.bindSelectImage();
            this.bindSearch();
            this.bindImageSelection();
            this.bindLoadMoreBtn();
            this.bindUpload();

            FilesTree.init(this)

            this.addUploader();

            ImageDataEdit.init()
        },
        setModal: function (modal) {
            this.modal = modal;
            this.searchinput = modal.find('input.searchinput');
            this.imageholder = modal.find('.modal-images-holder');
        },
//        setExplicitTextInput: function (input) {
//
//            this.textinput = input;
//        },
        getTextInput: function () {
            return ModalTextInput.getInput()
        },
        bindSelectImage: function () {
            modalChooser = this;
            $('.js-choose-image').on('click', function () {
                modalChooser.modal.modal('hide');
                modalChooser.populateSelectedImageData();
            })
        },
        bindSearch: function () {

            button = this.modal.find('.btn-search-images');

            modalChooser = this;

//                this.searchinput.keyup(function (e) {
//                    e.preventDefault();
//                    if (e.keyCode == 13)
//                    {
//                        e.preventDefault();
//                        button.trigger('click');
//                    }
//                });

            this.searchinput.onEnter(function (e) {
                e.preventDefault();
                button.trigger('click');
            });

            button.on('click', function () {
                modalChooser.setSearchFunction(modalChooser.performTermSearch);
                modalChooser.fetchImages(true)
//                modalChooser.pagination = 1;
//                modalChooser.resetData();
//                modalChooser.performTermSearch();
            })

            //button.on('click',)
        },
        setSearchFunction: function (func) {
            this.current_search_function = func;
        },
        fetchImages: function (reset_data) {
            if (typeof reset_data !== 'undefined' && reset_data === true) {
                modalChooser.pagination = 1;
                modalChooser.resetData();
            }
            this.modal.find('.ajax-loader').show();
            modalChooser.current_search_function();
        },
        resetData: function () {
            this.removeImages();
            this.num_loaded = 0;
            this.num_total = 0;
            $('.num-loaded').html('-')
            $('.num-total').html('-')
        },
        performTermSearch: function () {
            var term = modalChooser.searchinput.val();

            var url = this.modal.data('targeturl');
            form_data = {
                'search-criteria': term,
                'page': modalChooser.pagination,
            }


            $.ajax({
                url: url,
                method: 'GET',
                data: form_data,
                success: function (msg) {
                    modalChooser.modal.find('.ajax-loader').hide();

                    modalChooser.loadedimages = msg.result;
                    // modalChooser.removeImages();

                    modalChooser.handleResults(msg);
//                    if (msg.result.length > 0) {
//                        modalChooser.createPreviewImages(msg.result);
//                        modalChooser.setNumStatus(msg.result.length, msg.total);
//                        modalChooser.pagination++;
//                        if (msg.last_page) {
//                            $('#load_results_btn').hide();
//                        } else {
//                            $('#load_results_btn').show();
//                        }
//                    } else {
//                        modalChooser.setNotFountMessage();
//                    }

                },
                error: function (msg) {
                    modalChooser.modal.find('.ajax-loader').hide();
                    modalChooser.imageholder.html('<strong>' + ReportMessages.message('error_searching_file') + '</strong>')

                }
            });
        },
        performDirFetch: function () {

            var modalChooser = this;
            var url = this.modal.find('.jstree').data('dirsearch')

            form_data = {
                'search-dir': this.current_dir,
                'page': modalChooser.pagination,
            }


            $.ajax({
                url: url,
                method: 'GET',
                data: form_data,
                success: function (msg) {

                    modalChooser.modal.find('.ajax-loader').hide();

                    modalChooser.loadedimages = msg.result;

                    modalChooser.handleResults(msg);
                    modalChooser.jstree.createNewNodes(msg.subdirectories);
                },
                error: function (msg) {
                    modalChooser.modal.find('.ajax-loader').hide();
                    modalChooser.imageholder.html('<strong>' + ReportMessages.message('error_searching_file') + '</strong>')

                }
            });

        },
        handleResults: function (server_response) {

            if (Object.keys(server_response.result).length > 0) {
                this.createPreviewImages(server_response.result);
                this.setNumStatus(server_response.result.length, server_response.total);
                this.pagination++;
                if (server_response.last_page) {
                    $('#load_results_btn').hide();
                } else {
                    $('#load_results_btn').show();
                }
            } else {
                this.setNotFountMessage();
            }
        },
        prependUploadedResults: function (server_response) {


            if (server_response.result.length > 0) {

                //     modalChooser.loadedimages = server_response.result;

                this.createUploadedImagesPreview(server_response.result)
            }
        },
        removeImages: function () {
            this.imageholder.html('');
        },
        createPreviewImages: function (images) {


            var modalChooser = this;
            $.each(images, function (key, item) {
                var preview = modalChooser.createImagePreview(key, item);



                modalChooser.imageholder.append(preview);
            });
            this.bindDeleteImage();
        },
        createUploadedImagesPreview: function (images) {
            var modalChooser = this;
            modalChooser.imageholder.prepend('<div class="col-xs-12 clearfix" style="border-top: 1px solid #ddd;"></div>');

            var last_datakey = modalChooser.loadedimages.length;

            $.each(images, function (key, item) {
                modalChooser.loadedimages[item.id] = item;
                preview = modalChooser.createImagePreview(item.id, item);
                modalChooser.imageholder.prepend(preview);
            });
            this.bindDeleteImage();

        },
        createImagePreview: function (key, image) {

            if (!image.additional_info) {
                return;
            }

            return image.rendered_module_item

//            var parent_div = $('<div/>', {
//                class: 'col-sm-3 modal-image-wrapper',
//                'data-imagekey': key,
//            })
//
//            var img_w = $('<div/>', {
//                class: 'img-w',
//                style: 'background-image: url("' + image.featuredThumb.uri + '")',
//            }).appendTo(parent_div);
//
////            img = $('<img/>', {
////                src: image.featuredThumb.uri,
////            }).appendTo(img_w);
//
//            p_info = $('<div/>', {
//                class: 'infodata'
//            }).appendTo(parent_div);
//
//            text_info = '<span class="word-break">' + image.featuredThumb.basename + '</span>';
////            for (key in image.text_info) {
////                info = image.text_info[key];
////                text_info += "<p><strong>" + key + ":</strong> " + info + "</p>";
////            }
//            p_info.html(
//                    text_info
//                    );
//
//            delete_btn = $('<button/>', {
//                class: 'btn btn-circle btn-icon-only red-pink js-btn-image-delete',
//                'data-toggle': "confirmation",
//                'data-content': image.id,
//                'data-placement': "left"
//            }).appendTo(parent_div);
//
//            icon = $('<i/>', {
//                class: "icon-trash"
//            }).appendTo(delete_btn);

//            return parent_div;
        },
        bindImageSelection: function () {
            modalChooser = this;


            this.modal.on('click', '.modal-image-wrapper', function (e) {

                modalChooser.setSelectedImage($(this).data('imagekey'))
                $('.modal-image-wrapper').removeClass('bg-blue');
                $(this).addClass('bg-blue');
                e.stopPropagation()
            })

            this.modal.on('dblclick', '.modal-image-wrapper', function (e) {

                modalChooser.setSelectedImage($(this).data('imagekey'))
                $('.modal-image-wrapper').removeClass('bg-blue');
                $(this).addClass('bg-blue');

                modalChooser.modal.modal('hide');
                modalChooser.populateSelectedImageData();
                e.stopPropagation()
            })


            this.modal.on('click', '.modal-images-holder', function () {
                $('.modal-image-wrapper').removeClass('bg-blue');
                modalChooser.removeSelectedImage()
            })


        },
        bindLoadMoreBtn: function () {
            modalChooser = this;
            $('#load_results_btn').on('click', function () {

                // modalChooser.performTermSearch();
                modalChooser.fetchImages()
            })
        },
        setSelectedImage: function (key) {
            this.selectedimage = this.loadedimages[key];
        },
        removeSelectedImage: function (key) {
            this.selectedimage = null;
        },
        populateSelectedImageData: function () {

            image = this.selectedimage;

            if (image === null) {
                return;
            }

            var filename_selected = this.constructSelectedFilename();

//for ckeditor, emit event

            var select_event = new CustomEvent("vt_selected_image", {detail: filename_selected});

            document.dispatchEvent(select_event);


            this.getTextInput().val(filename_selected);
            this.getTextInput().trigger('change')

            img_preview_holder = this.getTextInput().closest('.article-image-holder').find('.news-image-preview');

            img_preview_holder.find('.img-preview').css({display: 'block', 'background-image': 'url(' + image.featuredThumb.uri + ')'});

            img_preview_holder.find('.img-preview').find('img').attr('src', image.featuredThumb.uri);
            img_preview_holder.find('.img-preview').show();
            img_preview_holder.find('.img-fallback').hide();
            img_preview_holder.find('.img-name').text(image.name);
            img_preview_holder.find('.img-author').text(image.author);
            img_preview_holder.find('.img-year').text(image.year);
            img_preview_holder.find('#image_signature').text(image.description);
        },
        constructSelectedFilename: function () {

            image_url = this.selectedimage.url;

            dirname = new String(image_url).substring(0, image_url.lastIndexOf('/'));
            filename = new String(image_url).substring(image_url.lastIndexOf('/') + 1);


            thumb_dir = $('.thumb-size:checked').val();
            if (thumb_dir.length > 0) {
                thumb_dir = thumb_dir + '/';
            }

            return dirname + '/' + thumb_dir + filename;
        },
        setNumStatus: function (results_count, total_count) {
            if (results_count && total_count) {
                this.num_loaded += results_count;
                this.num_total = total_count;

                $(".num-loaded").html(this.num_loaded);
                $(".num-total").html(this.num_total);
            }
        },
        setNotFountMessage: function () {
            this.imageholder.html('<strong>' + ReportMessages.message('error_chooser_no_images_found') + '</strong>');
        },
        bindUpload: function () {
            $('.js-upload-image-btn').on('click', function () {
                $('.image_select_holder').hide();
                $('.image_upload_holder').fadeIn();
                $('.js-upload-image-btn').prop('disabled', true);
                $('.js-back').prop('disabled', false).addClass("yellow");
            })

            $('.js-back').on('click', function () {
                $('.image_upload_holder').hide();
                $('.image_select_holder').fadeIn();
                $('.js-upload-image-btn').prop('disabled', false);
                $('.js-back').prop('disabled', true).removeClass("yellow");
            })
        },
        setCurrentDirInfo: function (path) {
            $('.js-chosen-directory').html(path);
            this.modal.find('.destination-input').val(path)
        },
        addUploader: function () {
            var doc_input = this.modal.find('.js-files-uploader');
            var doc_preview = this.modal.find('.js-imagespreview');
            FilesUploader.init(doc_input, doc_preview);
            //  FilesUploader.init('#files-upload-input', '#imagespreview');
            FilesUploader.setModalChooser(this)
        },
        bindDeleteImage: function () {

            $('.js-btn-image-delete').on('click', function (e) {
                e.stopPropagation();
            })

            $('.js-btn-image-delete').confirmation({
                onConfirm: function (e) {
                    e.preventDefault();

                    var image_id = $(this).attr('content')
                    image_holder = $('.js-btn-image-delete[data-content=' + image_id + ']').closest('.modal-image-wrapper')


                    $.ajax({
                        url: '/uprava/galleryimage/' + image_id,
                        method: 'DELETE',
                        data: {},
                        success: function (msg) {
                            toastr.success('Successfuly deleted.')
                            image_holder.slideUp(200)
                        },
                        error: function (msg) {
                            toastr.error('Error occcured during deleting.')

                        }
                    });
                }
            });
        }

    }
}();


var ImageDataEdit = function () {
    return{
        init: function () {
            this.triggerform()
            this.onSubmitEditform()
        },
        triggerform: function () {
            $('.chooser-modal').on('click', '.btn-file-edit', function (e) {
                e.stopPropagation();
                var holder = $(this).closest('.modal-image-wrapper');
                var form = holder.find('.edit-imgdata-form');

                form.show();
            });

            $('.chooser-modal').on('click', '.edit-imgdata-form', function (e) {
                e.stopPropagation()
            })

            $('.chooser-modal').on('click', '.form-close', function (e) {
                var holder = $(this).closest('.modal-image-wrapper');
                var form = holder.find('.edit-imgdata-form');

                form.hide();
            })


        },
        onSubmitEditform: function () {

            $('.chooser-modal').on('submit', '.edit-imgdata-form', function (e) {
                e.preventDefault();

                var holder = $(this).closest('.modal-image-wrapper');
                var form = holder.find('.edit-imgdata-form');
                var image_id = holder.data('imagekey');

                $.ajax({
                    url: '/uprava/image/' + image_id,
                    method: 'PATCH',
                    data: form.serialize(),
                    success: function (msg) {
                        toastr.success('Successfuly updated.')
                        form.fadeOut(100)
                    },
                    error: function (msg) {
                        toastr.error('Error occcured during updating.')

                    }
                });

            })
        }
    }
}();



