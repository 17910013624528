var FilesTree = function () {
    return {
        holder: null,
        modalChooser: null,
        init: function (modalChooser) {
            this.modalChooser = modalChooser;
            modalChooser.jstree = this;
            this.holder = modalChooser.modal.find('.file_structure')
            this.createTree();
            this.bindClickSearch();
        },
        createTree: function () {
            filestree = this;
            var holder = this.holder;
            this.holder.jstree({
                "core": {
                    'check_callback': function (op, data) {
                        if (op === 'delete_node') {

                            // if true deleting, not removing impossible name directory
                            if (!data.dont_force_check) {
                                if (confirm('Are you sure?')) {
                                    console.log('Peform deleting')
                                    console.log(op)
                                    console.log(data)
                                    var parent_node = $('#' + data.parent);
                                    filestree.deleteDirectoryCall(filestree.getDirectoryPath($('#' + data.id)));
                                    console.log(parent_node);
                                    parent_node.find('a')[0].click()
                                }
                            }
//                          
                        }
                    },
                    "themes": {
                        "responsive": false
                    }
                },
                "types": {
                    "default": {
                        "icon": "fa fa-folder icon-state-warning icon-lg"
                    },
                    "file": {
                        "icon": "fa fa-file icon-state-warning icon-lg"
                    }
                },
                "state": {"key": "demo2"},
                "plugins": ["types", "contextmenu", "state"],
                "contextmenu": {
                    "items": function ($node) {


                        var tmp = $.jstree.defaults.contextmenu.items();
                        delete tmp.create.action;
                        delete tmp.ccp;
                        tmp.create.label = "New";
                        tmp.create.action = function (data) {
                            var inst = $.jstree.reference(data.reference),
                                    obj = inst.get_node(data.reference);
                            inst.create_node(obj, {}, "last", function (new_node) {
                                new_node.data = {file: true};
                                setTimeout(function () {
                                    inst.edit(new_node);
                                }, 0);
                            });
                        };
                        if (this.get_type($node) === "file") {
                            delete tmp.create;
                        }
                        return tmp;

                    }
                }
            }).bind("create.jstree", function (e, data) {

            }).bind("after_open.jstree", function (e, data) {

            }).bind("select_node.jstree", function (e, data) {

            }).bind("create_node.jstree", function (e, data) {

            }).on('rename_node.jstree', function (e, data) {
                filestree.renameDirectoryCall(filestree.getDirectoryPath($('#' + data.node.id)), data.text, data.old, data);
            });
        },
        bindClickSearch: function () {
            var filestree = this;
            this.holder.on('select_node.jstree', function (e, data) {
                // var link = $('#' + data.selected).find('a');
                var link = $('#' + data.selected).find('a.jstree-clicked');

                var search_path = filestree.getDirectoryPath(link)

                filestree.modalChooser.setSearchFunction(filestree.modalChooser.performDirFetch)
                filestree.modalChooser.current_dir = search_path;

                filestree.modalChooser.fetchImages(true)
                filestree.modalChooser.setCurrentDirInfo(search_path);
//                if (link.attr("href") != "#" && link.attr("href") != "javascript:;" && link.attr("href") != "") {
//                    if (link.attr("target") == "_blank") {
//                        link.attr("href").target = "_blank";
//                    }
//                    document.location.href = link.attr("href");
//                    return false;
//                }
            });
        },
        getDirectoryPath: function (directory_link) {
            if (!directory_link) {
                directory_link = this.holder.find('a.jstree-clicked');
            }

            var this_dir_name = '';
            if (directory_link.closest('li').attr('id') !== 'j1_1') {
                this_dir_name = directory_link.text().trim();
            }

            if (directory_link.closest('ul').length > 0 && directory_link.closest('ul').parent('li').length > 0) {
                var parent_a_link = directory_link.closest('ul').parent('li').children('a');
                return this.getDirectoryPath(parent_a_link) + '/' + this_dir_name;
            } else {

                return this_dir_name;
            }
        },
        renameDirectoryCall: function (path, new_name, old_name, data) {
            var url = this.holder.data('dirrename');
            var filestree_instance = this.holder.jstree(true);

            form_data = {
                'root_path': path,
                'old_name': old_name,
                'new_name': new_name
            }

            $.ajax({
                url: url,
                method: 'POST',
                data: form_data,
                success: function (msg) {
                    if (msg.success === false) {
                        toastr.error(msg.error_message);
                        data.node.dont_force_check = true;
                        filestree_instance.delete_node(data.node)
                    }
                },
                error: function (msg) {

                }
            });
        },
        deleteDirectoryCall: function (path) {
            var url = this.holder.data('dirdelete');
            console.log(this.holder)
            var filestree_instance = this.holder.jstree(true);
            form_data = {
                'root_path': path,
            }



            // return;
            $.ajax({
                url: url,
                method: 'POST',
                data: form_data,
                success: function (msg) {

                },
                error: function (msg) {

                }
            });
        },
        createNewNodes: function (nodes) {

            if (nodes.length > 0) {
                var parent = '#' + this.holder.jstree().get_selected()[0];
                var current_children = this.holder.jstree().get_children_dom(parent)

                var curr = current_children.map(function () {
                    return $(this).find('a').text().replace(/^\s+|\s+$/g, '')
                }).toArray()


                for (var key in nodes) {
                    node = nodes[key];

                    if ($.inArray(node.dirname, curr) === -1) {

                        this.holder.jstree().create_node(parent, {text: node.dirname})
                    }
                }

                this.holder.jstree().open_node(parent)
            }

        }
    }
}();